/*
 * @Author: 向佐贵 xiangzg@weixinkd.com
 * @Date: 2024-12-23 14:54:31
 * @LastEditors: 向佐贵 xiangzg@weixinkd.com
 * @LastEditTime: 2024-12-23 15:20:03
 * @FilePath: /youth-mall-admin-new/src/router/modules/xbzh.ts
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import Layout from "@/layouts/index.vue";
// @ts-ignore
import { UserAddIcon, ViewListIcon, RootListIcon } from "tdesign-icons-vue";

export default [
  {
    path: "/xbzh",
    name: "xbzh",
    component: Layout,
    meta: { title: "小白智慧", icon: RootListIcon },
    children: [
      {
        path: "product/list-zh",
        name: "productZh",
        component: () => import("@/pages/xbzh/index.vue"),
        meta: {
          title: "商品列表",
          keepAlive: true,   // 此组件需要被缓存
        },
      },
      {
        path: "order/list-zh",
        name: "orderZh",
        component: () => import("@/pages/xbzh/order.vue"),
        meta: {
          keepAlive: true,
          title: "订单列表"
        }
      },
    ]
  }
];