import VueRouter from "vue-router";
import dashboard from "./modules/dashboard";
import data from "./modules/data";
import content from "./modules/content";
import setting from "./modules/setting";
import user from "./modules/user";
import live from "./modules/live";
import ai from "./modules/ai";
import marketing from "./modules/marketing";
import privateDomain from "./modules/privateDomain";
import supplier from "./modules/supplier";
import radar from "./modules/radar";
import product from "./modules/product";
import order from "./modules/order";
import Layout from "@/layouts/index.vue";
import xb from './modules/xbProduct'
import xbzh from './modules/xbzh'
import advertisement from './modules/advertisement'
import { DashboardIcon, DesktopIcon } from "tdesign-icons-vue";
import Vue from "vue";

const env = import.meta.env.MODE || "development";
// 存放动态路由
export const asyncRouterList = [...dashboard, ...supplier, ...radar, ...product, ...content, ...xb, ...xbzh, ...order, ...live, ...data, ...privateDomain, ...advertisement, ...marketing, ...ai, ...user, ...setting];
// 存放固定的路由
const defaultRouterList = [
  {
    path: "/",
    component: () => import("@/pages/login/index.vue")
  },
  {
    path: "/login",
    name: "login",
    component: () => import("@/pages/login/index.vue")
  },
  {
    path: "/live/trend",
    component: () => import("@/pages/live/trend/index.vue"),
    meta: { title: "直播大盘", icon: DesktopIcon }
  },
  {
    path: "/qrcode",
    name: "qrcode",
    component: () => import("@/pages/login/wechatCode.vue")
  },
  {
    path: "/fs-auth-result",
    name: "fsAuthResult",
    component: () => import("@/pages/auth/fs/index.vue")
  },
  {
    path: "/add_article",
    name: "addArticle",
    component: Layout,
    meta: { title: "文章详情", icon: DashboardIcon },
    children: [
      {
        path: "/add_article",
        meta: { title: "文章详情", icon: DesktopIcon },
        component: () => import("@/pages/content/article/activity/components/index.vue")
      }
    ]
  },
  {
    path: "/add_landing",
    name: "addLanding",
    component: Layout,
    meta: { title: "快速建站" },
    children: [
      {
        path: "/add_landing",
        meta: { title: "快速建站", icon: DesktopIcon },
        component: () => import("@/pages/advertisement/advertisementManage/landingPage/create.vue")
      }
    ]
  },
  {
    path: "/live_info",
    name: "liveInfo",
    component: Layout,
    meta: { title: "直播详情", icon: DashboardIcon },
    children: [
      {
        path: "/live_info",
        meta: { title: "直播详情", icon: DesktopIcon },
        component: () => import("../pages/content/live/dy/liveInfo.vue")
      }
    ]
  },
  {
    path: "/order/detail",
    name: "orderDetail",
    component: Layout,
    meta: { title: "订单详情", icon: DashboardIcon, single: true },
    children: [
      {
        path: "/order/detail",
        component: () => import("@/pages/order/storeOrderDetail/index.vue"),
        meta: { title: "订单详情", icon: DesktopIcon }
      }
    ]
  },
  {
    path: "/xb/product/info",
    name: "xbProduct",
    component: Layout,
    meta: { title: "小白商品", icon: DashboardIcon, single: true },
    children: [
      {
        path: "/xb/product/info",
        component: () => import("@/pages/xbReport/addProduct.vue"),
        meta: { title: "商品详情", icon: DesktopIcon }
      }
    ]
  },
  {
    path: "/xbzh/product/info-zh",
    name: "xbzhProductZh",
    component: Layout,
    meta: { title: "小白智慧商品", icon: DashboardIcon, single: true },
    children: [
      {
        path: "/xbzh/product/info-zh",
        component: () => import("@/pages/xbzh/addProduct.vue"),
        meta: { title: "商品详情", icon: DesktopIcon }
      }
    ]
  },
  {
    path: "/product/detail",
    name: "productDetail",
    component: Layout,
    meta: { title: "商品详情", icon: DashboardIcon, single: true },
    children: [
      {
        path: "/product/detail",
        component: () => import("@/components/editProductInfo/index.vue"),
        meta: { title: "商品详情", icon: DesktopIcon }
      }
    ]
  },
  {
    path: "/create/product",
    name: "createProduct",
    component: Layout,
    meta: { title: "商品详情", icon: DashboardIcon, single: true },
    children: [
      {
        path: "/create/product",
        component: () => import("@/components/createProductInfo/index.vue"),
        meta: { title: "商品详情", icon: DesktopIcon }
      }
    ]
  },
  {
    path: "/live/central/control",
    name: "CentralControl",
    component: Layout,
    meta: { title: "直播中控台", icon: DashboardIcon, single: true },
    children: [
      {
        path: "/live/central/control",
        component: () => import("@/pages/live/liveCentralControl/index.vue"),
        meta: { title: "直播中控台", icon: DesktopIcon }
      }
    ]
  },
  ...asyncRouterList
];
const createRouter = () =>
  new VueRouter({
    mode: "history",
    base: env === "site" ? "/starter/vue/" : null,
    routes: defaultRouterList,
    scrollBehavior() {
      return { x: 0, y: 0 };
    }
  });
const router = createRouter();

export function resetRouter() {
  const newRouter = createRouter();
  router.matcher = newRouter.matcher; // reset router
}

export default router;
